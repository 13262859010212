<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      v-for="vehicle in vehicles"
      :key="vehicle.id"
      class="form-wrapper full add-form"
    >
      <div class="form-wrapper width-1_3">
        <base-input
          :label="`${$t('COMMON.VEHICLE')} (*)`"
          :placeholder="$t('COMMON.VEHICLE')"
          @change="onFormChanged()"
          disabled
        >
          <span>{{ $objectDenomination(vehicle) }}</span>
        </base-input>
      </div>

      <div class="form-wrapper width-1_3">
        <base-input :label="`${$t('EVENTS.COLUMN')}`">
          <el-select
            :label="$t('EVENTS.COLUMN')"
            :placeholder="$t('EVENTS.COLUMN')"
            v-model="vehicle.express_auction_entry_column"
            :disabled="!canEdit"
            @change="
              (column) => {
                vehicle.express_auction_entry_column = column;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in EXPRESS_ENTRY_COLUMNS"
              :key="key"
              :value="value"
              :label="value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="form-wrapper width-1_3">
        <base-input
          type="number"
          min="1"
          :label="`${$t('EVENTS.ENTRY_NUMBER')} (*)`"
          :placeholder="$t('EVENTS.ENTRY_NUMBER')"
          v-model="vehicle.express_auction_entry_number"
          @change="onFormChanged()"
          :disabled="!canEdit"
        />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="vehicles.length === 0 || !canEdit || loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ $t("COMMON.SAVE") }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { eventTypeOptions } from "@/constants/common";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import { EXPRESS_ENTRY_COLUMNS } from "@/constants/auctions";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
  },

  mixins: [formMixin],

  props: ["eventId", "canEdit"],

  data() {
    return {
      loading: false,
      vehicles: [],
      EXPRESS_ENTRY_COLUMNS,
    };
  },

  created() {
    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;

      try {
        const params = {
          filter: {
            is_validated: 1,
            event_id: this.eventId,
          },
          sort: "express_auction_entry_number",
        };

        await this.$store.dispatch("vehicles/list", params);
        this.vehicles = this.$store.getters["vehicles/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.loading = false;
    },
    async handleSubmit() {
      this.loading = true;

      try {
        const map = this.vehicles.map((vehicle) => {
          return {
            id: vehicle.id,
            express_auction_entry_column: vehicle.express_auction_entry_column,
            express_auction_entry_number: vehicle.express_auction_entry_number,
          };
        });

        await this.$store.dispatch("events/reorganizeVehicles", {
          eventId: this.eventId,
          vehicles: map,
        });

        this.$notify({
          type: "success",
          message: this.$t("EVENTS.REORGANIZED_VEHICLES"),
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.loading = false;
    },

    onFormChanged() {},
  },

  watch: {
    // formErrors(errors) {
    //   if (errors) {
    //     this.setApiValidation(errors);
    //   }
    // },
    eventId(eventId) {
      if (eventId) {
        this.eventId = eventId;
        this.getList();
      }
    },
  },
};
</script>
